import { gql, useQuery } from '@apollo/client';
import React, { useContext } from 'react';
import {
  activeHouseNavigationHelper,
  activeHouseNavigationHelperVariables,
} from '~/types/generated/activeHouseNavigationHelper';
import { useActiveHouseId } from './useActiveHouseId';

/** Generic high-level query for navigation and account environment, should prevent fetching details for each of the connected nodes */
const ACTIVE_HOUSE_NAVIGATION_HELPER = gql`
  query activeHouseNavigationHelper($houseId: ID!) {
    house(id: $houseId) {
      id
      orders {
        id
        isPaid
        isCancelled
      }
      requestedDuties {
        name
      }
      advice {
        id
        isRequested
        isSent
        isPaid
      }
      intake {
        id
        isPlanned
        isStarted
      }
      sentQuotes {
        id
        isExpired
        isAccepted
        isCancelled
      }
      customer {
        id
      }
    }
    customerIntakeByHouse(houseId: $houseId) {
      submittedOn
    }
  }
`;

export const ActiveHouseProgressContext = React.createContext<
  activeHouseNavigationHelper | undefined
>(undefined);

export const ActiveHouseProgressProvider: React.FC = ({ children }) => {
  const { activeHouseId } = useActiveHouseId();
  const { data } = useQuery<activeHouseNavigationHelper, activeHouseNavigationHelperVariables>(
    ACTIVE_HOUSE_NAVIGATION_HELPER,
    {
      variables: { houseId: activeHouseId },
      skip: !activeHouseId,
    },
  );
  return (
    <ActiveHouseProgressContext.Provider value={data}>
      {children}
    </ActiveHouseProgressContext.Provider>
  );
};

export function useActiveHouseProgress(): activeHouseNavigationHelper | undefined {
  return useContext(ActiveHouseProgressContext);
}
